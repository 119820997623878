import * as React from "react";
import { PLAY_STORE_URL } from "../utils/detect-os";

export default function Google() {
  React.useEffect(() => {
    window.location.replace(PLAY_STORE_URL);
  })
  return (
    <div>
    </div>
  );
}
